<template>
  <transition name="f-fade">
    <div class="fixed inset-0 bg-gray-200/75 backdrop-blur-sm z-[1000]" v-if="localIsLoading">
      <section class="absolute-center flex-center flex-col p-8 bg-shell-100 shadow max-w-sm">
        <u-icon v-if="localIcon" :name="localIcon" class="text-heroblue text-4xl mb-2" />
        <p v-if="localMessage" v-html="localMessage" class="mb-6 text-center text-3xl font-serif" />
        <core-busy-dots />
      </section>
    </div>
  </transition>
</template>

<script setup>
  const eventBus = useEventBus('core-loading-set');
  const localIsLoading = ref(false);
  const localMessage = ref('');
  const localIcon = ref(null);

  function setLoading({isLoading, message, icon}) {
    localIsLoading.value = isLoading;
    localMessage.value = message;
    localIcon.value = icon;
  }

  onMounted(() => eventBus.on(setLoading));
  onUnmounted(() => eventBus.off(setLoading));
</script>
